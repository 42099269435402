/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");
@import url("./assets/css/bubble.css");
@import url("./assets/icons/flaticon/flaticon.css");
//@import url('./assets/icons/flaticon1/flaticon.css');
@import "~bootstrap-darkmode/darktheme";
@import "~ngx-sharebuttons/themes/default/default-theme";

// regular style toast
@import "~ngx-toastr/toastr";

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~ngx-toastr/toastr-bs4-alert";

@font-face {
    font-family: London;
    src: url("./assets/css/london.otf") format("opentype");
}
@font-face {
  font-family: 'Mister-London-Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Mister-London-Sans'),
    url('./assets/css/Mister-London-Sans.otf') format('opentype');
}
.toast-container {
  z-index: 9999999999999;
}

.toast-error {
  background-color: #dc3545;
  border: none;
}

.toast-success {
  background-color: #19b36b;
  border: none;
}

.toast-info {
  background-color: #181818;
  border: none;
}

.toast-warning {
  background-color: var(--bs-yellow);
  color: var(--bs-gray-dark) !important;
  border: none;
}

.bollino {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.bollino.red {
  background-color: #ff828e;
  border: 1px solid #da0015;
}

.bollino.yellow {
  background-color: #ffea82;
  border: 1px solid #ff7907;
}

.bollino.green {
  background-color: #9bff82;
  border: 1px solid #22ff07;
}

.web3modal-modal-lightbox {
  z-index: 999999 !important;
}

#uvp {
  display: none;
}

.iubenda-black {
  color: #1d1b1d;
  font-weight: bold;
  font-family: "PT Mono", monospace;
}
.solidBg {
  background-color: #000000 !important;
}

html,
body {
  height: auto;
  margin: 0;
  font-family: "Poppins", sans-serif;
  min-height: 100%;
}

body {
  background-color: #f883af;
  background:linear-gradient(rgba(0, 0, 0, 0.65), rgba(115, 112, 112, 0.65)), url("./assets/img/Home/seq_8_1_016.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode:overlay;
}

.badge {
  position: absolute;
  left: 25px;
  margin: 1.5em 3em;
  width: 4em;
  height: 6.2em;
  border-radius: 10px;
  display: flex;
  top: 0;
  transition: all 0.2s ease;
  justify-content: center;
  align-items: center;
}

.badge:before,
.badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge:before {
  transform: rotate(60deg);
}

.badge:after {
  transform: rotate(-60deg);
}

.badge:hover {
  //top: -4px;
}

.badge .circle {
  min-width: 50px;
  height: 50px;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  line-height: 50px;
  font-size: 1.5em;
}

.badge .circle i.fa {
  font-size: 2em;
  margin-top: 8px;
}

.badge .font {
  display: inline-block;
  margin-top: 1em;
}

.badge .ribbon {
  position: absolute;
  border-radius: 4px;
  padding: 5px 5px 4px;
  width: 120px;
  z-index: 11;
  color: #fff;
  bottom: 2px;
  left: 50%;
  margin-left: -60px;
  height: 25px;
  font-size: 14px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 27%);
  text-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  text-transform: uppercase;
  background: linear-gradient(to bottom right, #555 0%, #333 100%);
  cursor: default;
}

.purple {
  background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
  color: #7127a8;
}

.gold {
  background: linear-gradient(to bottom right, #ffc107 0%, #fd7e14 100%);
  color: #fd7e14;

  .ribbon {
    width: 140px;
    margin-left: -70px;
  }
}

[data-theme="dark"] nav {
  background-color: #222;
}

[data-theme="light"] nav {
  background-color: #fff;
}

.centerAlignVertical {
  align-items: center;
  height: 100%;
}

.centerAlignHorizontal {
  justify-content: center;
}

.height100 {
  height: 100% !important;
}

.row {
  margin: 0;
}

.flex {
  display: flex;
}

.lds-ring.white div {
  border: 2px solid #e3dfd3;
  border-color: #e3dfd3 transparent transparent transparent;
}

.lds-ring {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 2px;
  border: 2px solid #323132;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #323132 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes shrink {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

div#bubbleArea span.glow {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  border-radius: 200px;
  animation-name: bluePulse;
  -webkit-animation-name: bluePulse;
  -moz-animation-name: bluePulse;
  -o-animation-name: bluePulse;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

div#bubbleArea span.bubble {
  display: block;
  background: #fff;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(105, 171, 223, 0.2)),
    color-stop(100%, #5fe1db)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3369abdf', endColorstr='#5FE1DB', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 100px;
  height: 100px;
  border-radius: 200px;
  position: absolute;
  left: 25%;
  bottom: 110px;
  animation-name: bubbleFloat1;
  -webkit-animation-name: bubbleFloat1;
  -moz-animation-name: bubbleFloat1;
  -o-animation-name: bubbleFloat1;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

div#bubbleArea span.bubble2 {
  display: block;
  background: #fff;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(105, 171, 223, 0.2)),
    color-stop(100%, #5fe1db)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3369abdf', endColorstr='#5FE1DB', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 30px;
  height: 30px;
  border-radius: 60px;
  position: absolute;
  left: 20%;
  bottom: 110px;
  animation-name: bubbleFloat2;
  -webkit-animation-name: bubbleFloat2;
  -moz-animation-name: bubbleFloat2;
  -o-animation-name: bubbleFloat2;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

div#bubbleArea span.bubble3 {
  display: block;
  background: #fff;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(105, 171, 223, 0.2)),
    color-stop(100%, #5fe1db)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3369abdf', endColorstr='#5FE1DB', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: absolute;
  left: 50%;
  bottom: 110px;
  animation-name: bubbleFloat3;
  -webkit-animation-name: bubbleFloat3;
  -moz-animation-name: bubbleFloat3;
  -o-animation-name: bubbleFloat3;
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  -moz-animation-duration: 7s;
  -o-animation-duration: 7s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

div#bubbleArea span.bubble4 {
  display: block;
  background: #fff;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(105, 171, 223, 0.2)),
    color-stop(100%, #5fe1db)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(105, 171, 223, 0.2) 0%,
    #5fe1db 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3369abdf', endColorstr='#5FE1DB', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 80px;
  height: 80px;
  border-radius: 160px;
  position: absolute;
  left: 97px;
  bottom: 110px;
  animation-name: bubbleFloat4;
  -webkit-animation-name: bubbleFloat4;
  -moz-animation-name: bubbleFloat4;
  -o-animation-name: bubbleFloat4;
  animation-duration: 10s;
  -webkit-animation-duration: 10s;
  -moz-animation-duration: 10s;
  -o-animation-duration: 10s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ff9800;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.mid {
  right: 25px;
  left: inherit;
}

@keyframes shakeHatch {
  0% {
    -webkit-transform: rotate(0);
  }

  10% {
    -webkit-transform: rotate(10deg);
  }

  20% {
    -webkit-transform: rotate(-10deg);
  }

  30% {
    -webkit-transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(5deg);
  }

  70% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.item-hints .hint {
  width: 60px;
  height: 60px;
  position: relative;
}

/*.item-hints .hint::after{ green glow
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border-radius: 50%;
  width: 2px;
  height: 2px;
  z-index: 1;
  -webkit-box-shadow: 0 0 50px 30px rgba(72,170,72,.3);
  box-shadow: 0 0 50px 30px rgba(72,170,72,.3);
  -webkit-animation: home_hero_item_hints_glow 2s cubic-bezier(.25,.1,.2,1) infinite;
  animation: home_hero_item_hints_glow 2s cubic-bezier(.25,.1,.2,1) infinite;
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
}*/
@-webkit-keyframes home_hero_item_hints_glow {
  0% {
    -webkit-box-shadow: 0 0 30px 5px #48aa48;
    box-shadow: 0 0 30px 5px #48aa48;
  }

  70% {
    -webkit-box-shadow: 0 0 70px 50px rgba(72, 170, 72, 0);
    box-shadow: 0 0 70px 50px rgba(72, 170, 72, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 50px rgba(72, 170, 72, 0);
    box-shadow: 0 0 0 50px rgba(72, 170, 72, 0);
  }
}

@keyframes home_hero_item_hints_glow {
  0% {
    -webkit-box-shadow: 0 0 30px 5px #48aa48;
    box-shadow: 0 0 30px 5px #48aa48;
  }

  70% {
    -webkit-box-shadow: 0 0 70px 50px rgba(72, 170, 72, 0);
    box-shadow: 0 0 70px 50px rgba(72, 170, 72, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 50px rgba(72, 170, 72, 0);
    box-shadow: 0 0 0 50px rgba(72, 170, 72, 0);
  }
}

.item-hints .hint-dot {
  z-index: 3;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  -webkit-transform: translate(-0%, -0%) scale(0.95);
  transform: translate(-0%, -0%) scale(0.95);
  -webkit-animation: home_hero_item_hints_border 2s linear infinite;
  animation: home_hero_item_hints_border 2s linear infinite;
  margin: auto;
}

@-webkit-keyframes home_hero_item_hints_border {
  0%,
  100% {
    border-color: rgba(255, 255, 255, 0.6);
    -webkit-transform: translate(-50%, -50%) scale(0.95);
    transform: translate(-0%, -0%) scale(0.95);
  }

  50% {
    border-color: rgba(255, 255, 255, 0.3);
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-0%, -0%) scale(1);
  }
}

@keyframes home_hero_item_hints_border {
  0%,
  100% {
    border-color: rgba(255, 255, 255, 0.6);
    -webkit-transform: translate(-50%, -50%) scale(0.95);
    transform: translate(-0%, -0%) scale(0.95);
  }

  50% {
    border-color: rgba(255, 255, 255, 0.3);
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-0%, -0%) scale(1);
  }
}

.item-hints .hint-radius {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: background-color, opacity, visibility,
    -webkit-transform;
  transition-property: background-color, opacity, visibility, -webkit-transform;
  transition-property: background-color, opacity, visibility, transform;
  transition-property: background-color, opacity, visibility, transform,
    -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease, ease, ease,
    cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: ease, ease, ease, cubic-bezier(0.5, 0, 0, 1);
}

.item-hints .hint:hover .hint-radius {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.item-hints .hint[data-position="1"] .hint-content {
  top: 85px;
  left: 50%;
  margin-left: 56px;
}

.item-hints .hint-content {
  color: #fff;
  width: 300px;
  position: absolute;
  z-index: 5;
  padding: 12px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  pointer-events: none;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
}

.item-hints .hint:hover .hint-content {
  color: #fff;
  width: 300px;
  position: absolute;
  z-index: 5;
  padding: 12px 0;
  opacity: 1;
  visibility: visible !important;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  pointer-events: none;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
}

.item-hints .hint-content::before {
  width: 0px;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  transition: width 0.4s;
}

.item-hints .hint:hover .hint-content::before {
  width: 180px;
  transition: width 0.4s;
}

.item-hints .hint-content::after {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
  bottom: 0;
  left: 0;
  width: 80px;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.item-hints .hint:hover .hint-content::after {
  opacity: 1;
  visibility: visible;
}

.item-hints .hint[data-position="4"] .hint-content {
  bottom: 85px;
  left: 50%;
  margin-left: 56px;
}