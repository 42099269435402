@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?050eb37b82ce4c97e37da1ec58eb933c") format("truetype"),
url("./flaticon.woff?050eb37b82ce4c97e37da1ec58eb933c") format("woff"),
url("./flaticon.woff2?050eb37b82ce4c97e37da1ec58eb933c") format("woff2"),
url("./flaticon.eot?050eb37b82ce4c97e37da1ec58eb933c#iefix") format("embedded-opentype"),
url("./flaticon.svg?050eb37b82ce4c97e37da1ec58eb933c#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-brain:before {
    content: "\f101";
}
.flaticon-crazy:before {
    content: "\f102";
}
.flaticon-drooling:before {
    content: "\f103";
}
.flaticon-empowerment:before {
    content: "\f104";
}
.flaticon-fun-glasses:before {
    content: "\f105";
}
.flaticon-hospital:before {
    content: "\f106";
}
.flaticon-lazy:before {
    content: "\f107";
}
.flaticon-like:before {
    content: "\f108";
}
.flaticon-sparks:before {
    content: "\f109";
}
