@-webkit-keyframes bluePulse {
  from {
    -webkit-box-shadow: 0 0 30px #5FE1DB;
  }
  50% {
    -webkit-box-shadow: 0 0 80px #5FE1DB;
  }
  to {
    -webkit-box-shadow: 0 0 30px #5FE1DB;
  }
}
/* Bubble */
/* Bubble - Glow */
/* Mixing Animation Float Bubbles */
/* 
 * Bubble 1
 * $nr: 1;
 *
 */
@keyframes bubbleFloat1 {
  from {
    bottom: 0px;
    transform: scale(0.9);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    transform: scale(0.3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    transform: scale(0.8);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 545px;
    transform: scale(0.3);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 550px;
    transform: scale(3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    transform: scale(0.9);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes bubbleFloat1 {
  from {
    bottom: 0px;
    -webkit-transform: scale(0.9);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -webkit-transform: scale(0.3);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -webkit-transform: scale(0.8);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 545px;
    -webkit-transform: scale(0.3);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 550px;
    -webkit-transform: scale(3);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -webkit-transform: scale(0.9);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
}
@-moz-keyframes bubbleFloat1 {
  from {
    bottom: 0px;
    -moz-transform: scale(0.9);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -moz-transform: scale(0.3);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -moz-transform: scale(0.8);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 545px;
    -moz-transform: scale(0.3);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 550px;
    -moz-transform: scale(3);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -moz-transform: scale(0.9);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
}
@-o-keyframes bubbleFloat1 {
  from {
    bottom: 0px;
    -o-transform: scale(0.9);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -o-transform: scale(0.3);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -o-transform: scale(0.8);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 545px;
    -o-transform: scale(0.3);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 550px;
    -o-transform: scale(3);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -o-transform: scale(0.9);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
}
/* 
 * Bubble 2
 * 
 *
 */
/* Werte */
@keyframes bubbleFloat2 {
  from {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    transform: scale(0.3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    transform: scale(0.9);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 650px;
    transform: scale(0.3);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 655px;
    transform: scale(3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes bubbleFloat2 {
  from {
    bottom: 0px;
    -webkit-transform: scale(0.9);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -webkit-transform: scale(0.3);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 650px;
    -webkit-transform: scale(0.9);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 655px;
    -webkit-transform: scale(3);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
}
@-moz-keyframes bubbleFloat2 {
  from {
    bottom: 0px;
    -moz-transform: scale(0.9);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -moz-transform: scale(0.3);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 650px;
    -moz-transform: scale(0.9);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 655px;
    -moz-transform: scale(3);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
}
@-o-keyframes bubbleFloat2 {
  from {
    bottom: 0px;
    -o-transform: scale(0.9);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -o-transform: scale(0.3);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 545px;
    -o-transform: scale(0.9);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 550px;
    -o-transform: scale(3);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
}
/* 
 * Bubble 3
 * 
 *
 */
/* Werte */
@keyframes bubbleFloat3 {
  from {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    transform: scale(0.3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    transform: scale(0.9);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 490px;
    transform: scale(0.3);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 510px;
    transform: scale(3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes bubbleFloat3 {
  from {
    bottom: 0px;
    -webkit-transform: scale(0.9);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -webkit-transform: scale(0.3);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 490px;
    -webkit-transform: scale(0.9);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 510px;
    -webkit-transform: scale(3);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
}
@-moz-keyframes bubbleFloat3 {
  from {
    bottom: 0px;
    -moz-transform: scale(0.9);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -moz-transform: scale(0.3);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 490px;
    -moz-transform: scale(0.9);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 510px;
    -moz-transform: scale(3);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
}
@-o-keyframes bubbleFloat3 {
  from {
    bottom: 0px;
    -o-transform: scale(0.9);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -o-transform: scale(0.3);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 490px;
    -o-transform: scale(0.9);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 510px;
    -o-transform: scale(3);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
}
/* 
 * Bubble 3
 * 
 *
 */
/* Werte */
@keyframes bubbleFloat4 {
  from {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    transform: scale(0.3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    transform: scale(0.9);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 595px;
    transform: scale(0.3);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 620px;
    transform: scale(3);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    transform: scale(1);
    opacity: 0;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes bubbleFloat4 {
  from {
    bottom: 0px;
    -webkit-transform: scale(0.9);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -webkit-transform: scale(0.3);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 595px;
    -webkit-transform: scale(0.9);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 620px;
    -webkit-transform: scale(3);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -webkit-transform: scale(1);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
  }
}
@-moz-keyframes bubbleFloat4 {
  from {
    bottom: 0px;
    -moz-transform: scale(0.9);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -moz-transform: scale(0.3);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 595px;
    -moz-transform: scale(0.9);
    opacity: 1;
    -moz-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 620px;
    -moz-transform: scale(3);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -moz-transform: scale(1);
    opacity: 0;
    -moz-animation-timing-function: ease-in-out;
  }
}
@-o-keyframes bubbleFloat4 {
  from {
    bottom: 0px;
    -o-transform: scale(0.9);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  1% {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  30% {
    bottom: 0px;
    -o-transform: scale(0.3);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  95% {
    bottom: 595px;
    -o-transform: scale(0.9);
    opacity: 1;
    -o-animation-timing-function: ease-in-out;
  }
  99% {
    bottom: 620px;
    -o-transform: scale(3);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
  to {
    bottom: 0px;
    -o-transform: scale(1);
    opacity: 0;
    -o-animation-timing-function: ease-in-out;
  }
}
/**********************************************************/


.wrapper {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 70px;
}
#dot1,
#dot2,
#dot3,
#dot4,
#dot5,
#dot6,
#dot7,
#dot8,
#dot9,
#dot10 {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50px 50px;
  background: #000;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
  transform-origin: center center;
}
#dot2 {
  -webkit-animation: spin 1.5s -0.75s linear infinite;
          animation: spin 1.5s -0.75s linear infinite;
}
#dot3 {
  left: 30px;
  -webkit-animation: spin 1.5s -1.3s linear infinite;
          animation: spin 1.5s -1.3s linear infinite;
}
#dot4 {
  left: 30px;
  -webkit-animation: spin 1.5s -0.55s linear infinite;
          animation: spin 1.5s -0.55s linear infinite;
}
#dot5 {
  left: 60px;
  -webkit-animation: spin 1.5s -1.1s linear infinite;
          animation: spin 1.5s -1.1s linear infinite;
}
#dot6 {
  left: 60px;
  -webkit-animation: spin 1.5s -0.35s linear infinite;
          animation: spin 1.5s -0.35s linear infinite;
}
#dot7 {
  left: 90px;
  -webkit-animation: spin 1.5s -0.9s linear infinite;
          animation: spin 1.5s -0.9s linear infinite;
}
#dot8 {
  left: 90px;
  -webkit-animation: spin 1.5s -0.15s linear infinite;
          animation: spin 1.5s -0.15s linear infinite;
}
#dot9 {
  left: 120px;
  -webkit-animation: spin 1.5s -0.7s linear infinite;
          animation: spin 1.5s -0.7s linear infinite;
}
#dot10 {
  left: 120px;
  -webkit-animation: spin 1.5s 0.05s linear infinite;
          animation: spin 1.5s 0.05s linear infinite;
}
#base1,
#base2,
#base3,
#base4,
#base5 {
  position: absolute;
  top: 30px;
  left: 5px;
  width: 1px;
  height: 10px;
  background: #000;
  -webkit-animation: flex 1.5s linear infinite;
          animation: flex 1.5s linear infinite;
  transform-origin: center center;
}
#base2 {
  left: 35px;
  -webkit-animation: flex 1.5s -1.3s linear infinite;
          animation: flex 1.5s -1.3s linear infinite;
}
#base3 {
  left: 65px;
  -webkit-animation: flex 1.5s -1.1s linear infinite;
          animation: flex 1.5s -1.1s linear infinite;
}
#base4 {
  left: 95px;
  -webkit-animation: flex 1.5s -0.9s linear infinite;
          animation: flex 1.5s -0.9s linear infinite;
}
#base5 {
  left: 125px;
  -webkit-animation: flex 1.5s -0.75s linear infinite;
          animation: flex 1.5s -0.75s linear infinite;
}
@-webkit-keyframes spin {
  0%, 100% {
    transform: translateY(0px) scale(1);
  }
  25% {
    transform: translateY(30px) scale(2);
  }
  50% {
    transform: translateY(60px) scale(1);
  }
  75% {
    transform: translateY(30px) scale(0.3);
  }
}
@keyframes spin {
  0%, 100% {
    transform: translateY(0px) scale(1);
  }
  25% {
    transform: translateY(30px) scale(2);
  }
  50% {
    transform: translateY(60px) scale(1);
  }
  75% {
    transform: translateY(30px) scale(0.3);
  }
}
@-webkit-keyframes flex {
  0%, 100% {
    transform: scaleY(5);
  }
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(5);
  }
  75% {
    transform: scaleY(1);
  }
}
@keyframes flex {
  0%, 100% {
    transform: scaleY(5);
  }
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(5);
  }
  75% {
    transform: scaleY(1);
  }
}